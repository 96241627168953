import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { PopUp } from '@Core/Library'
import actions from '../../Redux/Action';
import MagicSliderDots from 'react-magic-slider-dots';
import SignInModal from 'Pages/CommnPages/SignInModal';

const ContactUs = (props) => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ],
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />;
    }
  };
  const [viewFlag, setViewFlag] = useState(false)
  const dispatch = useDispatch();
  let EmailUsFlag = useSelector((state) => state.productReducer.EmailUsFlag) || false
  const history = useHistory();
  // let queryParam = {}
  // history.location && history.location.search && history.location.search.split("?")[1].split("&").forEach(function (i) {
  //   return queryParam[i.split("=")[0]] = i.split("=")[1];
  // });

  const hiddenAnchorRef = useRef(null);
  let userDetails =
    props.userData &&
      props.userData.userData &&
      props.userData.userData.userDetails &&
      props.userData.userData.userDetails
      ? props.userData.userData.userDetails
      : {};
  let partnerCode = props.userData && props.userData.partnerCode || ''
  let routData = {
    "path": process.env.REACT_APP_HOME_ROUTE,
    "EmailUsFlag": true
  }
  useEffect(() => {
    if (EmailUsFlag && Object.keys(userDetails).length) {
      if (hiddenAnchorRef.current) {
        hiddenAnchorRef.current.click();
      }
      dispatch(actions.CommonActions.SetEmailUsFlag(false))
    }
    if (props.queryParam && props.queryParam.action && props.queryParam.action == 'email') {
      if (!props.token) {
        // PopUp(true, 'signInPopUpEmailUs')
        props.handleSignInModle(true, routData)
      } else if (props.isLoggedIn && Object.keys(userDetails).length) {
        // PopUp(false, 'signInPopUpEmailUs')
        props.handleSignInModle(false, {})
        if (hiddenAnchorRef.current) {
          hiddenAnchorRef.current.click();
        }
      }
      // setImmediate(() => {
      //   dispatch(actions.CommonActions.showLoader(false))
      // })
    }
  }, [userDetails])
  const handlecheckLogin = (e) => {
    e && e.preventDefault();
    if (!props.isLoggedIn) {
      // PopUp(true, 'signInPopUpEmailUs')
      props.handleSignInModle(true, routData)
      return
    } else {
      if (hiddenAnchorRef.current) {
        hiddenAnchorRef.current.click();
      }
    }
  }


  return (
    <div id='contact' className="su11-link-card__wrap show-desktop padding-top--16 padding-bottom--48 margin-bottom--48">
      <h2 className="su11-link-card__headline-text">Contact Info</h2>
      <div className={`su11-link-card__list su11-link-card__swiper ${!props.isMobile ? 'contactguid' : ''}  `}>
        <Slider  {...settings} className="su11-link-card__list-wrap contact-slider-desktop">

          <div className="su11-link-card__list-item card-visible">
            <div className="su11-link-card__list-item-wrap">
              <h3 className="su11-link-card__list-item-title">
                WhatsApp(English / हिंदी)
                <div className="su11-link-card__list-item-icon">
                  <div className="image image--main-loaded">
                    <img className="image_main responsive-img image--loaded" src="/img/contact/Whatsapp.png" alt="Whatsapp" />
                  </div>
                </div>
              </h3>
              <span className="su11-link-card__list-item-description">WhatsApp for Technical support or query, Service centre
                location, Repair status, Demo &amp; Installation request<br /> WhatsApp Us: <a href={process.env.REACT_APP_WHATSAPP_DESK} target='_blank'>1-800-5-726-7864 </a><br />Available 24 Hours / 7 days</span>
              <div className="su11-link-card__list-item-cta">
                <span className="su11-link-card__list-item-cta-wrap">
                  <a className="cta cta--contained cta--black" target='_blank' href={process.env.REACT_APP_WHATSAPP_DESK} onClick={e => props.handleLogging('', { ChildMenuRedirectURL: process.env.REACT_APP_WHATSAPP_DESK, ChildMenuName: 'WhatsApp' }, 1, 'Contact Info')}>
                    WhatsApp
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="su11-link-card__list-item card-visible">
            <div className="su11-link-card__list-item-wrap">
              <h3 className="su11-link-card__list-item-title">
                Sign Language
                <div className="su11-link-card__list-item-icon">
                  <div className="image image--main-loaded">
                    <img className="image__main responsive-img image--loaded" src="/img/contact/sign-language7.png" alt="sign-language" />
                  </div>
                </div>
              </h3>
              <span className="su11-link-card__list-item-description">Video call with Sign Language interpreter assisted by
                Samsung product expert.<br /><br />Operation Hour: Monday - Friday 11:00-16:00</span>
              <div className="su11-link-card__list-item-cta">
                <span className="su11-link-card__list-item-cta-wrap">
                  <a className="cta cta--contained cta--black cta--icon" href="https://wa.me/91180057267864?text=hi">
                    Video chat with us
                    <img className="icon" src="/img/svg-icons/outlink-bold.svg" alt="outlink-bold" />
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="su11-link-card__list-item card-visible">
            <div className="su11-link-card__list-item-wrap">
              <h3 className="su11-link-card__list-item-title">
                Email Support
                <div className="su11-link-card__list-item-icon">
                  <div className="image image--main-loaded">
                    <img className="image__main responsive-img image--loaded" src="/img/contact/email.png" alt="email" />
                  </div>
                </div>
              </h3>
              <span className="su11-link-card__list-item-description">We'll respond within 24 hours of your request.
                <br /><br />

                {/* <strong>Srilanka : support.lk@samsung.com</strong> */}
              </span>

              <div className="su11-link-card__list-item-cta">
                <span className="su11-link-card__list-item-cta-wrap">
                  <a className="cta cta--contained cta--black " href='' target="_self" onClick={e => { props.handleLogging('', { ChildMenuRedirectURL: 'Email Us', ChildMenuName: 'Email Support India' }, 1, 'Contact Info'); handlecheckLogin(e) }}>
                    Email Us
                  </a>
                  <a ref={hiddenAnchorRef} style={{ visibility: 'hidden' }} href={`mailto:support.india@samsung.com?subject=Query &body=Dear Customer,%0D%0A%0D%0A Please provide following details along with your query.%0D%0A%0D%0A 
					              %20%20%20%20%20%20%20%20First Name : ${userDetails.given_name || ''}  %0D%0A
                        Last Name :  ${userDetails.family_name || ''} %0D%0A
                        Contact Number : ${userDetails.mobileNumber || ''} %0D%0A
                        Customer ID : ${partnerCode || ''} %0D%0A
                        Product Model Number :  %0D%0A
                        Product Serial number :  %0D%0A
                        Address :  %0D%0A
                        Please describe your query : `}></a>
                </span>
                {/* <span className="su11-link-card__list-item-cta-wrap">
                <a className="cta cta--contained cta--black " href={process.env.REACT_APP_EMAIIL_SUPPORT_BANGLAGESH} target="_self"onClick={e=>props.handleLogging('',{ChildMenuRedirectURL:process.env.REACT_APP_EMAIIL_SUPPORT_BANGLAGESH,ChildMenuName:'Email Support Bangladesh'},1,'Contact Info')}>
                Bangladesh
                </a>
              </span> */}
                {/* <span className="su11-link-card__list-item-cta-wrap">
                <a className="cta cta--contained cta--black "  href="mailto:support.lk@samsung.com" onClick={e=>props.handleLogging('',{ChildMenuRedirectURL:"mailto:support.lk@samsung.com",ChildMenuName:'Email Support SriLanka'},1,'Contact Info')}>
                Sri Lanka
                </a>
              </span> */}
              </div>
            </div>
          </div>

          <div className="su11-link-card__list-item card-visible">
            <div className="su11-link-card__list-item-wrap">
              <h3 className="su11-link-card__list-item-title">
                Phone Support (India)
                <div className="su11-link-card__list-item-icon">
                  <div className="image image--main-loaded">
                    <img className="image__main responsive-img image--loaded" src="/img/contact/call.png" />
                  </div>
                </div>
              </h3>
              <span className="su11-link-card__list-item-description">
                <b>Available: 8 AM to 8 PM (All 7 days)</b>
                <br /> <br />
                <a className="cta cta--contained cta--black " href="" onClick={e => { e.preventDefault(); setViewFlag(!viewFlag) }}>
                  {viewFlag ? 'View less' : 'View more'}
                </a>
              </span>
              {viewFlag && <div className="su11-link-card__list-item-cta">
                <span className="su11-link-card__list-item-cta-wrap">
                  <a className="cta " href="tel:180057267864" target="_self" style={{ color: 'black' }}>
                    <b>1-800-5-726-7864</b>
                  </a>
                </span>

                <span className="su11-link-card__list-item-cta-wrap">
                  <a className="cta  cta--black " href="tel:1800407267864" target="_self" style={{ color: 'black' }}>
                    <b>1-800-40-726-7864</b>
                  </a>
                </span>
              </div>}
              {/* <div className="su11-link-card__list-item-cta">
                <span className="su11-link-card__list-item-cta-wrap">

                  <a className="cta cta--contained cta--black " href="tel:180057267864" target="_self">
                    ☏ : Tollfree Helpline 1
                  </a>
                </span>

                <span className="su11-link-card__list-item-cta-wrap">

                  <a className="cta cta--contained cta--black " href="tel:1800407267864" target="_self">
                    ☏ : Tollfree Helpline 2
                  </a>
                </span>
              </div> */}

            </div>
          </div>

          {/* <div className="su11-link-card__list-item card-visible">
          <div className="su11-link-card__list-item-wrap">
            <h3 className="su11-link-card__list-item-title">
              Phone Support
              <div className="su11-link-card__list-item-icon">
                <div className="image image--main-loaded">
                  <img className="image__main responsive-img image--loaded" src="/img/contact/call.png" alt="call"/>
                </div>
              </div>
            </h3>
            <span className="su11-link-card__list-item-description">
              (India, Bangladesh, Sri Lanka, Nepal)
            </span>
            <div className="su11-link-card__list-item-cta">
              <span className="su11-link-card__list-item-cta-wrap">
                <a className="cta cta--contained cta--black cta--icon" href={process.env.REACT_APP_PHONE_SUPPORT} onClick={e=>props.handleLogging('',{ChildMenuRedirectURL:process.env.REACT_APP_PHONE_SUPPORT,ChildMenuName:'Email Support SriLanka', ChildMenuName :'Phone Support'},1,'Contact Info')}>
                India, Bangladesh, Sri Lanka, Nepal Phone Support
                </a>
              </span>
            </div>
          </div>
        </div> */}

          <div className="su11-link-card__list-item card-visible">
            <div className="su11-link-card__list-item-wrap">
              <h3 className="su11-link-card__list-item-title">
                Apps &amp; Services Customer Support
              </h3>
              <span className="su11-link-card__list-item-description">Samsung Pay : 1800-123-7729<br />Samsung Free : 1800 40 SAMSUNG (7267864) </span>
              <div className="su11-link-card__list-item-cta">
              </div>
            </div>
          </div>


          <div className="su11-link-card__list-item card-visible">
            <div className="su11-link-card__list-item-wrap">
              <h3 className="su11-link-card__list-item-title">
                CEO Office
                <div className="su11-link-card__list-item-icon">
                  <div className="image image--main-loaded">
                    <img className="image__main responsive-img image--loaded" src="/img/contact/ceo-office.png" alt="ceo-office" />
                  </div>
                </div>
              </h3>
              <span className="su11-link-card__list-item-description">Share feedback to CEO office & relevant team will get in touch with you promptly </span>
              <div className="su11-link-card__list-item-cta">
                <span className="su11-link-card__list-item-cta-wrap">
                  <a className="cta cta--contained cta--black " href={process.env.REACT_APP_CEO_OFFICE} target="_self" onClick={e => props.handleLogging('', { ChildMenuRedirectURL: process.env.REACT_APP_CEO_OFFICE, ChildMenuName: 'CEO Office' }, 1, 'Contact Info')}>
                    Email Us
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      {/* <SignInModal  {...props} routData={routData} popupId={'signInPopUpEmailUs'} /> */}
    </div>

  );
};

export default ContactUs;
