import api from "@Core/Apis/api";
import apipaths from "@Core/Apis/apiPaths";
//import Apipaths from  '@Core/Apis/apiPaths'
//import { ActionTypes } from 'Redux/Contants/actionTypes'

const CommonAction = {

  getMenu: function (cb) {
    return (dispatch) => {
      api.setMethod("get").sendRequest(
        apipaths.menu,
        {},
        true,
        function (response) {
          let productsolution =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "productsolution"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "productsolution"
              )[0]) ||
            {};
          let quicklinks =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "quicklinks"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "quicklinks"
              )[0]) ||
            {};
          let exploreservice =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "exploreservice"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "exploreservice"
              )[0]) ||
            {};
          let sidemenu =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "sidemenu"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "sidemenu"
              )[0]) ||
            {};
          let solutionstips =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "solutionstips"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "solutionstips"
              )[0]) ||
            {};
          let productSolutionsTips =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "productSolutionsTips"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "productSolutionsTips"
              )[0]) ||
            {};
          let search =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "search"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "search"
              )[0]) ||
            {};
          let searchLinks =
            (response &&
              response.data &&
              response.data.Entity &&
              response.data.Entity.length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "searchLinks"
              ).length > 0 &&
              response.data.Entity.filter(
                (item) => item.ParentMenuType == "searchLinks"
              )[0]) ||
            {};
          dispatch({
            type: "menu",
            data: response.data.Entity ? response.data.Entity : {},
            productsolution,
            quicklinks,
            exploreservice,
            sidemenu,
            solutionstips,
            productSolutionsTips,
            search,
            searchLinks
          });
          cb && cb(response.data)
        },
        dispatch
      );
    };
  },
  signIn: function () {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.SSOConfiguration, {}, true, function (response) {
        if (response.data && response.data.IsSuccess) {
          let state = response.data.Entity && response.data.Entity.state || ''
          let clientId = response.data.Entity && response.data.Entity.client_id || ''
          let redirectUri = response.data.Entity && response.data.Entity.redirect_uri || ''
          let responseType = response.data.Entity && response.data.Entity.response_type || ''
          let scope = response.data.Entity && response.data.Entity.scope || ''
          let data = { ...response.data.Entity }
          sessionStorage.setItem('ssoconfig', JSON.stringify(data));
          let finalUrl = apipaths.signInURL + `response_type=${responseType}` + '&' + `client_id=${clientId}` + '&' + `redirect_uri=${redirectUri}` + '&' + `scope=` + '&' + `state=${state}`;
          window.location.href = finalUrl
        }
      },
        dispatch
      );
    };
  },
  DSCLogout: function (token, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.DSCLogout, {}, true, function (response) {
        sessionStorage.clear()
        dispatch(CommonAction.setLoggedIn(false));
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  getUserProduct: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.userProduct, data, true, function (response) {
        let data = {}
        if (response && response.data && response.data.IsSuccess && response.data.Entity) {
          let Token = response.data.Entity.dscAccessToken
          sessionStorage.setItem('token', btoa(JSON.stringify(Token)));
          data = { ...response.data.Entity.userDetails }

        }
        cb && cb(response.data)
        dispatch(CommonAction.setLoaderData(data));
      },
        dispatch
      );
    };
  },
  getUserDetailsWithToken: function (token, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.getUserDetailsWithToken, {}, true, function (response) {
        let userData = {}
        let data = {}
        if (response && response.data && response.data.IsSuccess) {
          userData['userDetails'] = response.data.Entity.userDetails
          userData['isUserExist'] = response.data.Entity.isUserExist
          userData['userDetails'] = { ...response.data.Entity.userDetails, mobileNumber: response.data.Entity.userDetails.mobile_number || '' }
          // if (response.data.Entity.userDetails && response.data.Entity.userDetails.preferred_username_type_code == '001') {
          //   userData['userDetails'] = { ...response.data.Entity.userDetails, mobileNumber: response.data.Entity.userDetails.preferred_username }
          // }
          let productList = (response && response.data && response.data.Entity && response.data.Entity.listProducts && response.data.Entity.listProducts) ||
            [];
          let loyaltyDetails = (response && response.data && response.data.Entity && response.data.Entity.loyaltyDetails && response.data.Entity.loyaltyDetails) ||
            {};
          data = {
            userData, productList, loyaltyDetails,
            dscAccessToken: response.data.Entity.dscAccessToken,
            ssoAccessToken: response.data.Entity.ssoAccessToken,
            isUserExist: response.data.Entity.isUserExist,
            partnerCode: response.data.Entity.partnerCode
          }
        }
        dispatch(CommonAction.setUserData(data));
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  UpdateUserConsent: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.UpdateUserConsent, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  submitRegistrationForm: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.BPCREATE, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  insertLoggingApi: function (data) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.insertLoggingApi, data, true, function (response) {
      },
        dispatch
      );
    };
  },
  searchPinCode: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.searchPinCode, data, true, function (response) {
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  getModelNumber: function (data) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.getModelNumber, data, true, function (response) {
        //cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  insertPickDropApi: function (token, data) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.insertPickDropApi, data, true, function (response) {
        //cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  SparePartResponse: function (data, cb) {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.SparePartResponse, data, true, function (response) {
        dispatch({
          type: "SparePartResponse",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  SparePartPrice: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.SparePartPrice, data, true, function (response) {
        dispatch({
          type: "SparePartPrice",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  RepairPartsDetails: function (data, cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.RepairPartsDetails, data, true, function (response) {
        dispatch({
          type: "RepairPartsDetails",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetProductIssueSubIssue: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetProductIssueSubIssue, data, true, function (response) {
        dispatch({
          type: "GetProductIssueSubIssue",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  ChatBotAPI: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.ChatBotAPI, data, true, function (response) {
        dispatch({
          type: "ChatBotAPI",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  InsertUpdateAgentSchedule: function (token, data, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.InsertUpdateAgentSchedule, data, true, function (response) {
        dispatch({
          type: "InsertUpdateAgentSchedule",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetTimeSlots: function (cb) {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.GetTimeSlots, {}, true, function (response) {
        dispatch({
          type: "GetTimeSlots",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetPromotionImages: function () {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.GetPromotionImages, {}, true, function (response) {
        dispatch({
          type: "GetPromotionImages",
          data: response.data.Entity ? response.data.Entity : {},
        });
      },
        dispatch
      );
    };
  },
  GetAgentScheduleData: function (token, cb) {
    return (dispatch) => {
      api.setMethod("post").setHeader('Token', token).sendRequest(apipaths.GetAgentScheduleData, {}, true, function (response) {
        dispatch({
          type: "GetAgentScheduleData",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetCouponGenerationURL: function (data,cb) {
    return (dispatch) => {
      api.setMethod("post").sendRequest(apipaths.GetCouponGenerationURL, data, true, function (response) {
        dispatch({
          type: "GetCouponGenerationURL",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  GetMetaTag: function (token, cb) {
    return (dispatch) => {
      api.setMethod("get").sendRequest(apipaths.GetMetaTagMasterDetailsDSC, {}, true, function (response) {
        dispatch({
          type: "GetMetaTag",
          data: response.data.Entity ? response.data.Entity : {},
        });
        cb && cb(response.data)
      },
        dispatch
      );
    };
  },
  setProductIssueSubIssue: function (data) {
    return {
      type: "GetProductIssueSubIssue",
      data,
    };
  },
  setBrowserName: function (data) {
    return {
      type: "browserName",
      data,
    };
  },
  setLoaderData: function (data) {
    return {
      type: "loaderData",
      data,
    };
  },
  setUserData: function (data) {
    return {
      type: "userProduct",
      data,
    };
  },
  setLoggedIn: function (data) {
    return {
      type: "isLoggedIn",
      data,
    };
  },
  setProductsSolutionChild: function (data) {
    return {
      type: "selectedProductSolution",
      data,
    };
  },
  setProductsDetails: function (data) {
    return {
      type: "selectedProduct",
      data,
    };
  },
  showProductPage: function (data) {
    return {
      type: "showProductPage",
      data,
    };
  },
  navMenu: function (data) {
    return {
      type: "navMenu",
      data,
    };
  },
  showLoader: function (data) {
    return {
      type: "showLoader",
      data,
    };
  },
  removeProductsSolutionChild: function () {
    return {
      type: "selectedProdSolChild",
      data: {},
    };
  },
  SetSpareRepaireData: function (data) {
    return {
      type: "SetSpareRepaireData",
      data,
    };
  },
  ResetSparePartPrice: function (data) {
    return {
      type: "SparePartPrice",
      data,
    };
  },
  SetSelfhelpData: function (data) {
    return {
      type: "SetSelfhelpData",
      data,
    };
  },
  setQrcodeStatus: function (data) {
    return {
      type: "setQrcodeStatus",
      data,
    };
  },
  SetEmailUsFlag: function (data) {
    return {
      type: "SetEmailUsFlag",
      data,
    };
  },
  SetSmartThingsData: function (data) {
    return {
      type: "SetSmartThingsData",
      data,
    };
  },
  // new code


  DeleteUserMasterAddress: function (data) {
    return {
      type: "deleteUserMasterAddress",
      data,
    };
  },

  // new code end
};

export default CommonAction;
