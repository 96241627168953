import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import actions from "../../../Redux/Action";
import CommonFunction from "@Core/Components/CommonFunction";
import Loader from './../Loader'
import Header from './Component/Header';
import Footer from './Component/Footer';
import MobQuickTips from '../../../Pages/CommnPages/MobQuickTips'
import MobContactUs from '../../../Pages/CommnPages/MobContactUs'
import { PopUp } from './../../Library';
import { toast } from 'react-toastify';
import { RegistrationModal } from '@Core/Components/Modal';
import ConsentModal from '@Core/Components/Modal/Consent'
import jQuery from 'jquery';
import SignInModal from 'Pages/CommnPages/SignInModal';
import HeaderOfferBanner from './Component/Header/HeaderOfferBanner';
import  CouponGenerate  from '@Core/Components/Modal/CouponGenerate';
import  OfferBannerModal  from '@Core/Components/Modal/OfferBannerModal';
const Layout = ({ Component, ...rest }) => {
  const history = useHistory();
  const [isMobileState, toggleIsMobile] = useState(Boolean(window.innerWidth < 767));
  const [siginRoute, setSigninRoute] = useState({ hideCancelBtn: false, routData: {} })
  const [chatFlag, setChatFlag] = useState(false)
  const [chatLoadFlag, setChatLoadFlag] = useState(false)
  const [chatDataFlag, setChatDataFlag] = useState('')
  let showLoader = useSelector((state) => state.productReducer.showLoader) || false
  let loaderData = useSelector((state) => state.productReducer.loaderData) || {}
  let isLoggedIn = useSelector((state) => state.productReducer.isLoggedIn) || false
  let isQrcode = useSelector((state) => state.productReducer.isQrcode) || false
  let userData = useSelector((state) => state.productReducer.userData) || {}
  let menu = useSelector((state) => state.productReducer.menu) || {}
  let solutionstips = useSelector((state) => state.productReducer.solutionstips) || {}
  let SelfhelpData = useSelector((state) => state.productReducer.SelfhelpData) || {}
  let PromotionImages = useSelector((state) => state.productReducer.PromotionImages) || []
  let navMenu = useSelector((state) => state.productReducer.navMenu) || "home"
  let pathName = history && history.location && history.location.pathname || '/'
  let browserName = CommonFunction.getBrowser()
  let token = CommonFunction.get('token') || '';
  let routeData = (sessionStorage.getItem('routeData')) || ''
  let globleQueryParam = {}
  let productionURL = window && window.location && window.location.origin === process.env.REACT_APP_PROD_IP ? true : false
  history.location && history.location.search && history.location.search.split("?")[1].split("&").forEach(function (i) {
    return globleQueryParam[i.split("=")[0]] = i.split("=")[1];
  });
  let HeaderBanner =PromotionImages && PromotionImages.length>0 && PromotionImages.filter(item=>item.Type === 'Header') || []
  let PopUpBanner =PromotionImages && PromotionImages.length>0 && PromotionImages.filter(item=>item.Type === 'Popup') || []
  
  const dispatch = useDispatch()
  const handleResize = () => {
    toggleIsMobile(Boolean(window.innerWidth < 767));
  };
  useEffect(() => {
    getBrowserInfo() // geting browser info 
    // getting resise esulation  of bowser 
    toggleIsMobile(Boolean(window.innerWidth < 767));
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    let queryParam = globleQueryParam || {};
    dispatch(actions.CommonActions.GetMetaTag())
    if (history.location && history.location.search) {
      dispatch(actions.CommonActions.showLoader(true))
      if (!queryParam.hasOwnProperty('scid') && !queryParam.hasOwnProperty('action')) handleRoute()
      if (queryParam.hasOwnProperty('action')) { // this section for open email option by redirect from .com
        if (isMobileState) dispatch(actions.CommonActions.navMenu('contactUs'))
        if (queryParam.action == 'bookappointment') {
          let routData = {
            "path": process.env.REACT_APP_BOOKAPPOINTMENT_ROUTE,
            "BookAppointmentData": { BAStep: 1, Tab: "newBook", error: {} }
          }
          handleLoggin('', routData);
        } else if (queryParam.action == 'pickdrop') {
          let routData = {
            "path": process.env.REACT_APP_PICKDROP_ROUTE,
            "PickDropData": { PDStep: 1, Tab: "newBook", error: {} }
          }
          handleLoggin('', routData);
        } else if (queryParam.action == 'selfhelp') {
          let routData = {
            "path": process.env.REACT_APP_SELFHELP_ROUTE,
            "SelfhelpData": { ...SelfhelpData }
          }
          handleLoggin('', routData);
        } else if (queryParam.action == 'webchat') {
          let routData = {
            "path": process.env.REACT_APP_HOME_ROUTE,
            "webchat": { 'redirect': true, error: {} }
          }
          handleLoggin('', routData);
        } else if (queryParam.action == 'smartthings') {
          let routData = {
            "path": process.env.REACT_APP_HOME_ROUTE,
            "SmartThingsData": { 'modelCode': queryParam.modelCode ,'serialNo': queryParam.serialNo ,'errorCode': queryParam.errorCode , error: {} }
          }
          handleLoggin('', routData);
        } else setImmediate(() => dispatch(actions.CommonActions.showLoader(false)))
      } else if (queryParam.hasOwnProperty('source')) { // this section for QRcode 
        queryParam = {}
        dispatch(actions.CommonActions.setQrcodeStatus(true))
        dispatch(actions.CommonActions.showLoader(false))
      } else if (queryParam.hasOwnProperty('code') && queryParam.hasOwnProperty('state')) AuthenticateQueryParams(queryParam); // this section for sso login 
      else setImmediate(() => dispatch(actions.CommonActions.showLoader(false)))
    }
    let flag = false
    let token = CommonFunction.get('token');
    if (token && !(Object.keys(userData).length)) {      // this section for get data from token
      dispatch(actions.CommonActions.showLoader(true))
      flag = true
      dispatch(actions.CommonActions.getUserDetailsWithToken(token, (response) => {
        if (response.IsSuccess) {
          if (response.Entity && !response.Entity.isUserExist) {
            PopUp(true, 'registrationModal')
          }
          dispatch(actions.CommonActions.setLoggedIn(true))
        } else {
          toast.error(response.Message || process.env.REACT_APP_INVALID_ATHENTICATION);
          sessionStorage.clear();
        }
        flag = true
        dispatch(actions.CommonActions.showLoader(false))
      }))
    }
    if (PromotionImages && PromotionImages.length === 0) dispatch(actions.CommonActions.GetPromotionImages())
    let pathname = history.location && history.location.pathname ? history.location.pathname : "";
    pathname && dispatch(actions.CommonActions.showProductPage(true))
    if (!menu || (menu && !Object.keys(menu).length)) { // this section for get menu data
      dispatch(actions.CommonActions.showLoader(true))
      dispatch(actions.CommonActions.getMenu((res) => {
        if (res.IsSuccess) {
          if (!(queryParam && Object.keys(queryParam).length) && !flag)
            dispatch(actions.CommonActions.showLoader(false))
        }
      }))
    }
    // ---------------------for gnb ------------------
    var winhref = window.location.href.replace("/content/samsung", "").replace(".html", "https://www.samsung.com/");
    if (winhref.indexOf("?") > 0) {
      winhref = winhref.substring(0, winhref.indexOf("?"));
    }
    var siteCode = winhref.split("https://www.samsung.com/")[3];

    //depth Info.
    var depth = winhref.split("https://www.samsung.com/").length;
    var depth_last = winhref.split("https://www.samsung.com/")[depth - 1];
    if (depth_last == "" || depth_last.charAt(0) == "?") {
      depth -= 1;
    }

    //set pathIndicator(not product page)
    var pageName = "";
    var depth_2 = "";
    var depth_3 = "";
    var depth_4 = "";
    var depth_5 = "";

    var digitalData = {
      "page": {
        "pageInfo": {
          "siteCode": "in",
          "pageName": pageName,
          "pageID": "L2NvbnRlbnQvc2Ftc3VuZy9pbi9zdXBwb3J0",
          "pageTrack": "support main",
          "originPlaform": "web"
        },
        "pathIndicator": {
          "depth_2": depth_2,
          "depth_3": depth_3,
          "depth_4": depth_4,
          "depth_5": depth_5
        }
      },
      "user": {
        "userDeviceList": [
        ]
      },
      "product": {
        "category": "",
        "model_code": "", // PD class정보 이용하여 설정
        "model_name": "", // PD page(server-side)
        "displayName": "", // PD class정보 이용하여 설정
        "pvi_type_code": "", //PD page(server-side)
        "pvi_type_name": "", //PD page(server-side)
        "pvi_subtype_code": "", //PD page(server-side)
        "pvi_subtype_name": "",//PD page(server-side)
        "pd_type": "", //PD type
        "content_id": "",
        "products": "",
        "prodView": ""
      }
    }
  }, [])
  
  // ************ web chat intigration ************ //
  useEffect(() => {
    function openWebchat() {
      jQuery('#webchat-holder').show();
      jQuery('#wrapper-form').hide();
    }
    function hideWebchat() {
      jQuery('#webchat-holder').hide();
    }
    function load() {
      console.log('load webchat==============')
      setChatLoadFlag(true)
      var script = document.createElement("script");
      if (productionURL) script.src = "https://webchatapi.samsungwacs.com/Webchat-samsung-iframe/uf-webchat.js?v=1.1"
      else script.src = "https://uatwebchatapi.samsungwacs.com/Webchat-samsung-iframe/uf-webchat.js?v=1.1"
      //script.src = "https://webchatapi.samsungwacs.com/Webchat-samsung-iframe/uf-webchat.js?v=1.1"
      console.log(productionURL,'productionURL')
      document.body.appendChild(script);
    }
    setTimeout(() => {
      !token && setChatFlag(true)
    }, 5000);
    if (token) load();
  }, [token])

  useEffect(() => {
    if (chatDataFlag && chatLoadFlag) {
      window.addEventListener('message', ReceiveDatafromChild, false);
      function ReceiveDatafromChild(e) {
        if (e.data == 'scriptsLoaded') {
          setChatDataFlag('')
          setImmediate(() => {
            console.log(e.data, 'ReceiveDatafromChild=====================================')
            let redirectSamsung = routeData && JSON.parse(routeData) && JSON.parse(routeData).webchat && JSON.parse(routeData).webchat.redirect ? true : false
            var data = { "Data": "Samsung", "Value": [{ "Key": "UserId", "Value": chatDataFlag }] }
            window.postMessage(data, '*');
            if (redirectSamsung) window.postMessage('startChat', '*');
            handleLogging('', { ChildMenuRedirectURL: 'unfyd', ChildMenuName: redirectSamsung ? 'WebChat Samsung.com' : 'WebChat DSC', ChildMenuTitle: chatDataFlag || '' }, 1, 'WebChat')
          })
        }
      }
    }
  }, [chatDataFlag])
  const getBrowserInfo = () => {
    dispatch(actions.CommonActions.setBrowserName(CommonFunction.getBrowser()))
  }
  const handleRoute = () => {
    if (routeData) {
      let data = JSON.parse(routeData)
      let path = data.path
      let BookAppointmentData = data.BookAppointmentData || {}
      let PickDropData = data.PickDropData || {}
      let SelfhelpData = data.SelfhelpData || {}
      let TrackDetailsData = data.TrackDetailsData || {}
      let SmartThingsData = data.SmartThingsData || {}
      let EmailUsFlag = data.EmailUsFlag || false
      let navMenu = data.navMenu || false
      history.push(path)
      if (path == process.env.REACT_APP_BOOKAPPOINTMENT_ROUTE && Object.keys(BookAppointmentData).length) {
        dispatch(actions.ServiceAction.SetBookAppointmentData({ ...BookAppointmentData }))
      } else if (path == process.env.REACT_APP_PICKDROP_ROUTE && Object.keys(PickDropData).length) {
        dispatch(actions.ServiceAction.SetPickDropData({ ...PickDropData }))
      } else if (path == process.env.REACT_APP_SELFHELP_ROUTE && Object.keys(SelfhelpData).length) {
        dispatch(actions.CommonActions.SetSelfhelpData({ ...SelfhelpData }))
      } else if (path == process.env.REACT_APP_TRACKDETAILS && Object.keys(TrackDetailsData).length) {
        dispatch(actions.ServiceAction.SetTrackDetailsData({ ...TrackDetailsData }))
      } else if (path == process.env.REACT_APP_HOME_ROUTE) {
        dispatch(actions.CommonActions.navMenu(navMenu))
        dispatch(actions.CommonActions.SetEmailUsFlag(EmailUsFlag))
        dispatch(actions.CommonActions.SetSmartThingsData(SmartThingsData))
      }
    } //else history.push(pathName)
  }
  const AuthenticateQueryParams = (queryParam) => {
    dispatch(actions.CommonActions.getUserProduct({ AuthCode: queryParam.code, State: queryParam.state }, (res) => {
      if (res.IsSuccess) {
        let token = res.Entity && res.Entity.dscAccessToken
        token && dispatch(actions.CommonActions.getUserDetailsWithToken(token, (response) => {
          if (response.IsSuccess) {
            //for user registraion if user is not exist 
            if (response.Entity && !response.Entity.isUserExist) {
              setTimeout(() => {
                PopUp(true, 'registrationModal')
              });
            }
            //for consent popup
            if (res.Entity && res.Entity.userDetails && Object.keys(res.Entity.userDetails).length && res.Entity.userDetails.IsConsent === false) {
              setTimeout(() => {
                PopUp(true, 'consentPopUp')
              });
            }
            //send user date to unfyd for session maintain
            if (res.Entity && res.Entity.userDetails && Object.keys(res.Entity.userDetails).length) {
              setChatDataFlag(res.Entity.userDetails.Encrypt_preferred_username)
            }
            dispatch(actions.CommonActions.showLoader(false))
            !routeData && history.push(process.env.REACT_APP_HOME_ROUTE)
          } else {
            dispatch(actions.CommonActions.showLoader(false))
            toast.error(response.Message || process.env.REACT_APP_INVALID_ATHENTICATION);
            sessionStorage.clear();
          }
          dispatch(actions.CommonActions.setLoaderData({}))
          dispatch(actions.CommonActions.showLoader(false))
          dispatch(actions.CommonActions.setLoggedIn(true))
        }))
        toast.success(res.Message || process.env.REACT_APP_LOGGEDIN_SUCCESSFULLY)
      } else {
        dispatch(actions.CommonActions.showLoader(false))
        toast.error(res.Message || process.env.REACT_APP_INVALID_ATHENTICATION);
      }
    }
    ))
  };

  const handleLoggin = (e, data = {}) => {
    e && e.preventDefault();
    if (!isLoggedIn) {
      dispatch(actions.CommonActions.showLoader(true))
      dispatch(actions.CommonActions.signIn())
    }
    if (isLoggedIn) {
      dispatch(actions.CommonActions.setUserData({}))
      dispatch(actions.CommonActions.setLoggedIn(false))
      sessionStorage.clear();
    }
    if (Object.keys(data).length) sessionStorage.setItem('routeData', (JSON.stringify(data)));
  }
  const handleNavManu = (e, manu) => {
    e && e.preventDefault();
    dispatch(actions.CommonActions.navMenu(manu))
    handleScroll()
  }
  const handleScroll = (selector = '') => {
    if (selector && document.querySelector(`.${selector}`)) {
      document.querySelector(`.${selector}`).scrollIntoView()
    }
    else window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  const handlePageRoute = (page, state = {}) => {
    if (page == 'home') history.push(process.env.REACT_APP_HOME_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'product') history.push(process.env.REACT_APP_PRODUCT_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'bookappointment') history.push(process.env.REACT_APP_BOOKAPPOINTMENT_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'pickdrop') history.push(process.env.REACT_APP_PICKDROP_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'repaircost') history.push(process.env.REACT_APP_REPAIRCOST_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'selfhelp') history.push(process.env.REACT_APP_SELFHELP_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'warrantypolicies') history.push(process.env.REACT_APP_WARRANTYPOLICIES.replace(/\"/g, ""), state)
    else if (page == 'requestservice') history.push(process.env.REACT_APP_REQUESTSERVICE.replace(/\"/g, ""), state)
    else if (page == 'trackrepair') history.push(process.env.REACT_APP_TRACKREPAIR.replace(/\"/g, ""), state)
    else if (page == 'samsung-signlanguage-service') history.push(process.env.REACT_APP_SIGNLANG_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'appointmenthistory') history.push(process.env.REACT_APP_BOOKAPPOINTMENT_ROUTE.replace(/\"/g, ""), state)
    else if (page == 'mailquestiongeneralnps') history.push(process.env.REACT_APP_MAILQESTIONGENERAL_NPS.replace(/\"/g, ""), state)
    else if (page == 'mailquestiongeneralnew') history.push(process.env.REACT_APP_MAILQESTIONGENERAL_NEW.replace(/\"/g, ""), state)
    else if (page == 'ekonnect') history.push(process.env.REACT_APP_EKONNECT_ROUTE.replace(/\"/g, ""), state)
  }
  const handleLogging = (e, data, index, event) => {
    e && e.preventDefault()
    let userDetails = userData && userData.userData && userData.userData.userDetails && userData.userData.userDetails ? userData.userData.userDetails : {};
    let EmailId = isLoggedIn && userDetails && Object.keys(userDetails).length ? userDetails.email : ''
    let loggingData = [
      {
        "EmailId": EmailId,
        "EventName": event,
        "BrowserName": browserName,
        "ChildMenuId": data.ChildMenuID,
        "LinkName": data.ChildMenuName,
        "LinkUrl": data.ChildMenuRedirectURL ? data.ChildMenuRedirectURL : process.env.REACT_APP_PRODUCT_ROUTE,
        "ProductName": data.ChildMenuTitle,
        "ProductCategory": data.ChildCatogoryCode,
        "ProductSequence": index,
        "SearchValue": data.ChildMenuTitle,
        "SourceType": isMobileState && isQrcode ? 3 : isMobileState && !isQrcode ? 1 : !isMobileState && isQrcode ? 4 : 2,
      }
    ]
    dispatch(actions.CommonActions.insertLoggingApi(loggingData))
  }
  const handleIntExtRoute = (e, data) => {
    e.preventDefault()
    if (data.ChildMenuType == '2') {
      //dispatch(actions.ServiceAction.SetTrackDetailsData({...data}))
      if (data.sourceName === "Quick Links" || data.sourceName == "Product") {
        if (Object.keys(data).length) sessionStorage.setItem('routeData', (JSON.stringify({ 'TrackDetailsData': data })));
      }
      if (data.ChildMenuRedirectURL === "#contact") {
        window.location.href = data.ChildMenuRedirectURL;
      }
      if (data && data.ParentName == "DIY") {
        let diyData = { search: data.Title || '', searchBy: 2, filterOpp: [], selectedCategory: data.CategoryCode || "", isGlobalSearch: true, diySearchId: data.ID }
        dispatch(actions.ServiceAction.SetDiyData(diyData))
      }
      if (data && data.ParentName == "Quick Links") {
        let trackData = {
          ChildCatogoryCode: "HHP,VDE,WSM,REF,ACN,HKE,COM,HOMEMOB,HOMEWEB",
          ChildMenuName: data.ID,
          ChildMenuRedirectURL: data.ChildMenuRedirectURL,
          ChildMenuTitle: data.Title,
          ChildMenuType: data.ChildMenuType,
          ParentMenuID: data.ParentName
        }
        //dispatch(actions.ServiceAction.SetTrackDetailsData(trackData))
        if (Object.keys(trackData).length) sessionStorage.setItem('routeData', (JSON.stringify({ 'TrackDetailsData': trackData })));
      }
      history.location.state && Object.keys(history.location.state).length ? history.push(data.ChildMenuRedirectURL, history.location.state) : history.push(data.ChildMenuRedirectURL)
      handleScroll()
    } else {
      window.open(data.ChildMenuRedirectURL, '_blank')
    }
  }
  const handleMobNum = (e) => {
    const { name, value } = e.target;
    let userDetails = userData && userData.userData && userData.userData.userDetails && userData.userData.userDetails ? userData.userData.userDetails : {};
    let mobileNumber = String(value).replace(/[^\d]/g, "");
    if (mobileNumber.length > 12) return;
    let data = {
      ...userData,
      userData: { userDetails: { ...userDetails, mobileNumber } },
    };
    dispatch(actions.CommonActions.setUserData(data));
  };
  // const handleMobNum = (e) => {
  //   const { name, value } = e.target;
  //   let userDetails = userData && userData.userData && userData.userData.userDetails && userData.userData.userDetails ? userData.userData.userDetails : {};
  //   let udata ={}
  //   if (name == 'mobileNumber'){
  //     let number = String(value).replace(/[^\d]/g, "");
  //     if (value.length > 12) return;
  //     udata =   { ...userDetails, [name]:number } 
  //   }else if (name == 'email'){ 
  //     udata =   { ...userDetails, [name]:value } 
  //   }

  //   let data = {
  //     ...userData,
  //     userData: { userDetails: udata },
  //   };
  //   dispatch(actions.CommonActions.setUserData(data));
  // };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    let token = CommonFunction.get('token');
    let userDetails = userData && userData.userData && userData.userData.userDetails && userData.userData.userDetails ? userData.userData.userDetails : {};
    let error = {};
    if (!userDetails.mobileNumber) {
      error.mobileNumber = true;
      toast.warn("Please Enter Mobile Number!")
      return;
    }
    if (!Object.keys(error).length) {
      let data = {
        NAME_FIRST: userDetails.given_name,
        NAME_LAST: userDetails.family_name,
        EMAIL: userDetails.email,
        MOBILE: userDetails.mobileNumber,
      };
      dispatch(actions.CommonActions.submitRegistrationForm(token, data, (res) => {
        if (res.IsSuccess) {
          let partnerCode =
            res.Entity && res.Entity.PARTNER ? res.Entity.PARTNER : "";
          let data = { ...userData, partnerCode, isUserExist: true };
          dispatch(actions.CommonActions.setUserData(data));
          PopUp(false, 'registrationModal')
          toast.success(res.Message || "Registration Successfully")
        } else {
          toast.error(res.Message)
        }
      }));
    }
  };
  const handleTakeConsent = (e, flag) => {
    e.preventDefault();
    if (flag) {
      //dispatch(actions.CommonActions.showLoader(true))
      dispatch(actions.CommonActions.UpdateUserConsent(token, { "IsConsent": true }, (response) => {
        if (response.IsSuccess) {
          PopUp(false, 'consentPopUp')
          toast.success(response.Message);
        } else {
          toast.error(response.Message);
        }
        dispatch(actions.CommonActions.showLoader(false))
      }))
    } else {
      PopUp(false, 'consentPopUp')
    }
  }
  const handleSignInModle = (flag, rdata, cancleBtn = false) => {
    setSigninRoute({ hideCancelBtn: cancleBtn, routData: rdata })
    setImmediate(() => {
      PopUp(flag, 'signInPopUp')
    })
  }
  return (
    <>
      <Loader showLoader={showLoader} loaderData={loaderData} />
      {HeaderBanner && HeaderBanner.length>0 ?<HeaderOfferBanner HeaderBanner={HeaderBanner}/>:''}
      <Header isMobile={isMobileState} handleLoggin={handleLoggin} handleNavManu={handleNavManu} handleLogging={handleLogging} handlePageRoute={handlePageRoute} home={true} navMenu={navMenu} userData={userData} isLoggedIn={isLoggedIn} token={token} pathName={pathName} />
      {navMenu == 'quickTips' ? (
        <MobQuickTips handleLogging={handleLogging} solutionstips={solutionstips} isMobile={isMobileState} isLoggedIn={isLoggedIn} token={token} />
      ) : navMenu == 'contactUs' ? (
        <MobContactUs handleLogging={handleLogging} isMobile={isMobileState} isLoggedIn={isLoggedIn} token={token} userData={userData} handleLoggin={handleLoggin} queryParam={globleQueryParam} handleSignInModle={handleSignInModle} />
      ) : <Component isMobile={isMobileState} {...rest} isLoggedIn={isLoggedIn} userData={userData} handleLogging={handleLogging} handlePageRoute={handlePageRoute} handleIntExtRoute={handleIntExtRoute} token={token} handleLoggin={handleLoggin} handleScroll={handleScroll} history={history} queryParam={globleQueryParam} handleSignInModle={handleSignInModle} />}
      <Footer isMobile={isMobileState} handleNavManu={handleNavManu} isLoggedIn={isLoggedIn} userData={userData} navMenu={navMenu} token={token} handlePageRoute={handlePageRoute} handleLogging={handleLogging} handleLoggin={handleLoggin} />
      <RegistrationModal token={token} handleFormSubmit={handleFormSubmit} handleMobNum={handleMobNum} userData={userData} />
      <ConsentModal token={token} handleTakeConsent={handleTakeConsent} />
      <CouponGenerate handleLogging={handleLogging} />
      {PopUpBanner && PopUpBanner.length>0 ?<OfferBannerModal PopUpBanner={PopUpBanner} isMobile={isMobileState} /> :''} 
      {/* <!-- Scroll To Top  --> */}
      <button className="fab scrolltop" title="Go to Top">
        Go to Top
        <img src="/img/svg-icons/up-highest-bold.svg" className="fab__icon" alt="go-to-top" />
      </button>
      <a className='feedbackBtn' href="https://survey3.medallia.com/?web&c=33&lng=en" target="_blank" onClick={e => handleLogging('', { ChildMenuRedirectURL: "https://survey3.medallia.com/?web&c=33&lng=en", ChildMenuName: 'Feedback' }, 1, 'Feedback')}>
        <img src="/img/feedback_tag.png" alt="feedback-img" />
      </a>
      <div className="footer-spacer show-mobile"></div>
      <div className="overlay hidden registration" id='overLay'></div>
      {!token && chatFlag ? <div className="static_pulse" onClick={e => handleSignInModle(true, { "path": process.env.REACT_APP_HOME_ROUTE, "webchat": { 'redirect': true, error: {} } })}></div> : ''}
      {!token ? <SignInModal routData={siginRoute.routData} handleLoggin={handleLoggin} hideCancelBtn={siginRoute.hideCancelBtn} /> : ''}
      <div id='unfydchat'></div>
    </>
  );
};

export default Layout;